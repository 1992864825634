import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search as SearchIcon, X } from 'lucide-react';
import { motion } from 'framer-motion';
import config from '../config';
import ExpandableCell from "./ExpandableCell";

const Search = () => {
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [showFilePopup, setShowFilePopup] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileSearchText, setFileSearchText] = useState('');

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/files`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };


    const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearching(true);
    setHasSearched(true);

    try {
      const response = await axios.post(`${config.BASE_API_URL}/api/search`, {
        text: searchText,
        category: category || undefined,
        file_ids: selectedFiles.map(file => file.id)
      });

      // Map file names to search results
      const resultsWithFileNames = response.data.items.map(item => ({
        ...item,
        fileName: files.find(file => file.id === item.price_item.file_id)?.display_name || 'Unknown'
      }));

      setSearchResults(resultsWithFileNames);
    } catch (error) {
      console.error('Error during search:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const toggleFileSelection = (file) => {
    setSelectedFiles(prev =>
      prev.some(f => f.id === file.id)
        ? prev.filter(f => f.id !== file.id)
        : [...prev, file]
    );
  };

  const removeSelectedFile = (file) => {
    setSelectedFiles(prev => prev.filter(f => f.id !== file.id));
  };

  const filteredFiles = files.filter(file =>
    file.name.toLowerCase().includes(fileSearchText.toLowerCase())
  );

  const renderFilePopup = () => (
    <div className="modal" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Выбор прайсов</h5>
            <button type="button" className="btn-close" onClick={() => setShowFilePopup(false)}></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={fileSearchText}
              onChange={(e) => setFileSearchText(e.target.value)}
              placeholder="Search files..."
              className="form-control mb-3"
            />
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {filteredFiles.map(file => (
                <div key={file.id} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={file.id}
                    checked={selectedFiles.some(f => f.id === file.id)}
                    onChange={() => toggleFileSelection(file)}
                  />
                  <label className="form-check-label" htmlFor={file.id}>
                    {file.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowFilePopup(false)}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderSelectedFileBadges = () => (
    <div className="d-flex flex-wrap mt-2">
      {selectedFiles.map(file => (
        <span key={file.id} className="badge bg-primary me-2 mb-2 d-flex align-items-center">
          {file.name}
          <X
            size={14}
            className="ms-1 cursor-pointer"
            onClick={() => removeSelectedFile(file)}
          />
        </span>
      ))}
    </div>
  );

  const renderResultsTable = () => (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Прайс</th>
            <th>Артикул</th>
            <th>Категория</th>
            <th>Цена</th>
            <th>Пояснение</th>
          </tr>
        </thead>
        <tbody>
          {searchResults.map((result, index) => (
            <tr key={index} className={result.matched ? 'table-success' : ''}>
              <td>
                {result.price_item.name}<br/>
                <small
                    className="badge bg-secondary">{result.price_item.main_attributes_string}</small>
                <ExpandableCell content={result.price_item.spec}/>
              </td>
              <td>{result.fileName}</td>
              <td>{result.price_item.product_id}</td>
              <td>{result.price_item.category || 'N/A'}</td>
              <td>{result.price_item.price_rrc || 'N/A'}р</td>
              <td>{result.explanation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="d-flex flex-column flex-grow-1">
      <motion.div
        initial={{ y: '30vh' }}
        animate={{ y: hasSearched ? 0 : '30vh' }}
        transition={{ duration: 0.5 }}
        className="w-100 sticky-top bg-white p-4"
      >
        <form onSubmit={handleSearch} className="mb-4">
          <div className="input-group mb-2">
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Поиск по позициям..."
              className="form-control"
            />
            <button
              type="submit"
              className="btn btn-primary"
            >
              <SearchIcon size={24} />
            </button>
          </div>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Категория (не обязательно)"
            className="form-control d-none"
          />
          <button
            type="button"
            onClick={() => setShowFilePopup(true)}
            className="btn btn-secondary mt-2"
          >
            Выберите прайсы
          </button>
          {renderSelectedFileBadges()}
        </form>
        {!hasSearched && (
          <div className="text-muted text-center mt-4">
            <p>Пример поиска:</p>
            <p>"бокорез 180мм"</p>
            <p>"сверло диаметром 4мм или 6мм"</p>
          </div>
        )}
      </motion.div>

      {showFilePopup && renderFilePopup()}

      {isSearching && (
        <div className="mt-4 text-center">
          <p>Поиск цен...</p>
          <div className="spinner-border text-primary">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {hasSearched && !isSearching && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-4 w-100 p-4"
        >
          <h2 className="fs-4 fw-bold mb-2">Результаты поиска</h2>
          {searchResults.length > 0 ? (
            renderResultsTable()
          ) : (
            <p className="text-muted">Ничего не найдено</p>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default Search;