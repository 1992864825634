import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Search, ExternalLink, ChevronUp, ChevronDown, Plus, X, FileDown, Filter, StopCircle } from 'lucide-react';
import config from '../config';
import VSEInstrumentiButton from "./buttons/VseInstrumentiButton";
import ExpandableCell from "./ExpandableCell";

const ProjectView = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [specItems, setSpecItems] = useState([]);
  const [searchResults, setSearchResults] = useState({});
  const [isSearching, setIsSearching] = useState({});
  const [files, setFiles] = useState({});
  const [matches, setMatches] = useState({});
  const [visibleItems, setVisibleItems] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Search
  const [isSearchingAll, setIsSearchingAll] = useState(false);
  const [searchAllProgress, setSearchAllProgress] = useState(0);
  const [isSearchParametersOpen, setIsSearchParametersOpen] = useState(false);
  const [searchAlreadyMatched, setSearchAlreadyMatched] = useState(false);

  const stopSearchRef = useRef(false);

  const observers = useRef({});

  useEffect(() => {
    fetchProject();
    fetchSpecItems();
    fetchFiles();
    return () => {
      // Cleanup observers on component unmount
      Object.values(observers.current).forEach(observer => observer.disconnect());
    };
  }, [projectId]);

  useEffect(() => {
    specItems.forEach(item => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          console.log("observe...")
          if (entry.isIntersecting) {
            setVisibleItems(prev => ({ ...prev, [item.id]: true }));
            fetchMatches(item.id);
            observer.unobserve(entry.target);
          }
        },
        { threshold: 0.1 } // 10% of the item is visible
      );

      const element = document.getElementById(`spec-item-${item.id}`);
      if (element) {
        observer.observe(element);
        observers.current[item.id] = observer;
      }
    });
  }, [specItems]);

  const fetchProject = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/projects/${projectId}`);
      setProject(response.data);

      // Set all price file IDs as selected by default
      setSelectedFiles(response.data.price_file_ids || []);
    } catch (error) {
      console.error('Error fetching project:', error);
      toast.error('Failed to fetch project details');
    }
  };

  const fetchMatches = useCallback(async (specItemId, forceFetch) => {
    if (!forceFetch && matches[specItemId]) return; // Don't fetch if we already have matches

    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/projects/${projectId}/spec/matches`);
      setMatches(prevMatches => ({
        ...prevMatches,
        [specItemId]: response.data.filter(match => match.spec_item_id === specItemId)
      }));
    } catch (error) {
      console.error('Error fetching matches:', error);
      toast.error('Failed to fetch matches');
    }
  }, [projectId, matches]);

  const fetchSpecItems = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/projects/${projectId}/spec_items`);
      setSpecItems(response.data);
    } catch (error) {
      console.error('Error fetching specification items:', error);
      toast.error('Failed to fetch specification items');
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/files`);
      const fileMap = {};
      response.data.forEach(file => {
        fileMap[file.id] = file;
      });
      setFiles(fileMap);
    } catch (error) {
      console.error('Error fetching files:', error);
      toast.error('Failed to fetch files');
    }
  };


  const handleSearch = async (itemUid) => {
    setIsSearching(prev => ({ ...prev, [itemUid]: true }));
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/projects/${projectId}/item/${itemUid}/search`, {
        params: { file_ids: selectedFiles }
      });
      setSearchResults(prev => ({ ...prev, [itemUid]: response.data }));
    } catch (error) {
      console.error('Error searching prices:', error);
      toast.error('Failed to search prices');
    } finally {
      setIsSearching(prev => ({ ...prev, [itemUid]: false }));
    }
  };

  const handleSearchAllClick = () => {
    setIsSearchParametersOpen(true);
  };

  const handleSearchAll = async () => {
    setIsSearchParametersOpen(false);
    setIsSearchingAll(true);
    setSearchAllProgress(0);
    stopSearchRef.current = false;

    const totalItems = specItems.length;
    let processedItems = 0;

    for (const item of specItems) {
      if (stopSearchRef.current) {
        break;
      }
      const itemMatches = matches[item.id] || [];
      if (!searchAlreadyMatched && itemMatches.length > 0) {
        processedItems++;
        setSearchAllProgress(Math.round((processedItems / totalItems) * 100));
        continue;
      }
      await handleSearch(item.id);
      processedItems++;
      setSearchAllProgress(Math.round((processedItems / totalItems) * 100));
    }

    setIsSearchingAll(false);
    if (stopSearchRef.current) {
      toast.info('Поиск по всем позициям прерван');
    } else {
      toast.success('Поиск по всем позициям завершен');
    }
  };

  const handleStopSearch = () => {
    stopSearchRef.current = true;
  };
  const handleAddMatch = async (specItemId, priceItemId) => {
    try {
      await axios.post(`${config.BASE_API_URL}/api/projects/${projectId}/spec/${specItemId}/add_match/${priceItemId}`);
      toast.success('Соответствие успешно добавлено');
      // Refresh the search results to reflect the change
      await fetchMatches(specItemId, true);
    } catch (error) {
      console.error('Error adding match:', error);
      toast.error('Не удалось выбрать соответствие');
    }
  };

  const handleRemoveMatch = async (specItemId, matchId) => {
    try {
      await axios.delete(`${config.BASE_API_URL}/api/projects/${projectId}/spec/matches/${matchId}`);
      toast.success('Match removed successfully');
      await fetchMatches(specItemId, true);
    } catch (error) {
      console.error('Error removing match:', error);
      toast.error('Failed to remove match');
    }
  };

  const handleGenerateCommercial = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/projects/${projectId}/generate_commercial`, {
        responseType: 'blob'
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/html' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Open the URL in a new tab
      window.open(url, '_blank');

      toast.success('Коммерческое предложение успешно сформировано');
    } catch (error) {
      console.error('Error generating commercial proposal:', error);
      toast.error('Не удалось сформировать коммерческое предложение');
    }
  };

  const toggleExpand = (specItemId) => {
    setExpandedItems(prev => ({
      ...prev,
      [specItemId]: !prev[specItemId]
    }));
  };

  const renderMatches = (specItemId) => {
    const itemMatches = matches[specItemId] || [];
    const isVisible = visibleItems[specItemId];
    const isExpanded = expandedItems[specItemId];

    const totalPrice = itemMatches.reduce((sum, match) => {
      const price = parseFloat(match.price_item.price_rrc) || 0;
      return sum + price;
    }, 0);

    const buttonClass = itemMatches.length ? "btn-outline-primary" : "btn-outline-secondary";

    return (
      <div className={`mt-2 ${isVisible ? 'fade-in' : ''}`}>
        <button
          className={"btn d-flex justify-content-between align-items-center w-100 " + buttonClass}
          onClick={() => toggleExpand(specItemId)}
        >
          <span>Выбранные позиции (<strong>{itemMatches.length}</strong>)</span>
          {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
        </button>
        {isExpanded && (
          itemMatches.length === 0 ? (
            <p className="text-muted mt-2">Ничего не выбрано. Нажмите Поиск</p>
          ) : (
            <div className="table-responsive mt-2">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Артикул</th>
                    <th>Прайс-лист</th>
                    <th>Наименование</th>
                    <th>Цена</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {itemMatches.map(match => (
                    <tr key={match.id}>
                      <td>{match.price_item.product_id}</td>
                      <td>{files[match.price_item.file_id]?.display_name || 'N/A'}</td>
                      <td>{match.price_item.name}</td>
                      <td>{match.price_item.price_rrc ? `${match.price_item.price_rrc}р` : 'N/A'}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleRemoveMatch(specItemId, match.id)}
                        >
                          <X size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-end"><strong>Итого:</strong></td>
                    <td><strong>{totalPrice.toFixed(2)}р</strong></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )
        )}
      </div>
    );
  };

  const handleFileSelection = (fileId) => {
    setSelectedFiles(prev =>
      prev.includes(fileId)
        ? prev.filter(id => id !== fileId)
        : [...prev, fileId]
    );
  };

  const renderFileFilter = () => (
    <div className="mb-3">
      <h5><Filter size={18} className="me-2" />Искать по прайс-листам</h5>
      {project?.price_file_ids.map(fileId => (
        <div key={fileId} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id={`file-${fileId}`}
            checked={selectedFiles.includes(fileId)}
            onChange={() => handleFileSelection(fileId)}
          />
          <label className="form-check-label" htmlFor={`file-${fileId}`}>
            {files[fileId]?.display_name || `File ${fileId}`}
          </label>
        </div>
      ))}
    </div>
  );

  const renderResultsTable = (results, specItemId) => {
    if (results.length === 0) {
      return <div className="mt-3 ps-5 alert alert-warning">Не найдено позиций с указанной спецификацией</div>;
    }

    return (
        <div className="table-responsive mt-3 ps-5">
          <table className="table table-striped">
            <thead>
            <tr>
              <th>Наименование</th>
              <th>Прайс</th>
              <th>Артикул</th>
              <th>Категория</th>
              <th>Цена</th>
              <th>Пояснение</th>
              <th>Действия</th>
            </tr>
            </thead>
            <tbody>
            {results.map((result, index) => (
                <tr key={index} className={result.matched ? 'table-success' : ''}>
                  <td>
                    {result.price_item.name}<br/>
                    <small
                        className="badge bg-secondary">{result.price_item.main_attributes_string}</small>
                    <ExpandableCell content={result.price_item.spec}/>
                  </td>
                  <td>
                    {files[result.price_item.file_id] ? (
                        <a href={`${config.BASE_API_URL}/api/files/${result.price_item.file_id}/show`}
                           target="_blank" rel="noopener noreferrer">
                          {files[result.price_item.file_id].display_name}
                        </a>
                    ) : (
                        result.price_item.file_id
                    )}
                  </td>
                  <td>{result.price_item.product_id}</td>
                  <td>{result.price_item.category || 'N/A'}</td>
                  <td>{result.price_item.price_rrc || 'N/A'}р</td>
                  <td>{result.explanation}</td>
                  <td>
                    <button
                        className="btn btn-sm btn-success"
                        onClick={() => handleAddMatch(specItemId, result.price_item.id)}
                    >
                      <Plus size={16} className="me-1"/>
                      Выбрать
                    </button>

                    <VSEInstrumentiButton itemName={result.price_item.name}/>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
    );
  }

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="project-view container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>{project.name}</h1>
        {!isSearchingAll ? (
            <button
              className="btn btn-primary me-2"
              onClick={handleSearchAllClick}
            >
              <Search size={18} className="me-2" />
              Поиск по всем позициям
            </button>
          ) : (
            <button
              className="btn btn-danger me-2"
              onClick={handleStopSearch}
            >
              <StopCircle size={18} className="me-2" />
              Остановить поиск
            </button>
          )}
        <button
            className="btn btn-success"
            onClick={handleGenerateCommercial}
        >
          <FileDown size={18} className="me-2"/>
          Сформировать КП
        </button>
      </div>
      <p>{project.description}</p>
      {isSearchingAll && (
        <div className="progress mb-3">
          <div
            className="progress-bar"
            role="progressbar"
            style={{width: `${searchAllProgress}%`}}
            aria-valuenow={searchAllProgress}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {searchAllProgress}%
          </div>
        </div>
      )}
      {renderFileFilter()}
      <div className="row">
        <div className="col-12">
          <h2>Спецификация</h2>
          <table className="table table-striped table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>Наименование</th>
              <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {specItems.map((item, index) => (
                <React.Fragment key={item.id}>
                  <tr id={`spec-item-${item.id}`}>
                    <td>{index + 1}</td>
                    <td>
                      <div>{item.name}</div>
                      <small className="text-muted">{item.info}</small>
                      <br/>
                      <span className="badge bg-secondary">{item.category}</span>
                      {renderMatches(item.id)}
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSearch(item.id)}
                          disabled={isSearching[item.id]}
                        >
                          {isSearching[item.id] ? (
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          ) : (
                            <Search size={18} className="me-2" />
                          )}
                          Поиск по прайсам
                        </button>
                        <VSEInstrumentiButton itemName={item.name} />
                      </div>
                    </td>
                  </tr>
                  {searchResults[item.id] && (
                    <tr>
                      <td colSpan="3">
                        {renderResultsTable(searchResults[item.id], item.id)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>


      <div className={`modal fade ${isSearchParametersOpen ? 'show' : ''}`} style={{display: isSearchParametersOpen ? 'block' : 'none'}} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Параметры поиска</h5>
              <button type="button" className="btn-close" onClick={() => setIsSearchParametersOpen(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="searchAlreadyMatched"
                  checked={searchAlreadyMatched}
                  onChange={(e) => setSearchAlreadyMatched(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="searchAlreadyMatched">
                  Искать по уже обработанным
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setIsSearchParametersOpen(false)}>Отмена</button>
              <button type="button" className="btn btn-primary" onClick={handleSearchAll}>Начать поиск</button>
            </div>
          </div>
        </div>
      </div>
      {isSearchParametersOpen && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default ProjectView;