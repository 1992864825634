import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import './App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
import Menu from "./components/Menu";
import FilesPage from "./pages/Files";
import Search from './components/Search';
import Projects from "./components/Projects";
import ProjectView from "./components/ProjectView";
import Login from './components/Login';
import { setAuthToken } from './utils/auth';
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import config from "./config";
import Expenses from "./pages/Expenses";

export const AuthContext = React.createContext();

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

function App() {
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          setAuthToken(token);
          const response = await axios.get(`${config.BASE_API_URL}/api/auth/users/me`);
          setUsername(response.data.username);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          localStorage.removeItem('token');
          setAuthToken(null);
        }
      }
      setIsAuthInitialized(true);
    };

    initializeAuth();
  }, []);

  const login = (token, user) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
    setUsername(user.username);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUsername('');
    setIsAuthenticated(false);
  };

  if (!isAuthInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, username, login, logout }}>
      <Router>
        <AppContent />
      </Router>
    </AuthContext.Provider>
  );
}

function AppContent() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div className="app">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {isAuthenticated && !isLoginPage && <Menu />}
      <div className={isLoginPage ? 'w-100' : 'container mt-3'}>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/files" component={FilesPage} />
          <PrivateRoute path="/search" component={Search} />
          <PrivateRoute exact path="/projects" component={Projects} />
          <PrivateRoute path="/projects/:projectId" component={ProjectView} />
          <PrivateRoute path="/expenses" component={Expenses} />
        </Switch>
      </div>
    </div>
  );
}

export default App;